import style from  "./Avviso.module.css";
import image from "../../data/img/error.png"
export default function Avviso({ idp }) {
    // const homepage = `${window.location.origin}/?IDP=${idp}`
    return (
        <div className={style.wrapperMain}>
            <div>
                <h1 className="titolo">Browser non supportato</h1>
                <p>Siamo spiancenti, per procedere all'acquisto effettua l'accesso a <strong>WeGest</strong> da un altro browser.</p>
                {/* <a href={homepage}>{homepage}</a> */}
            </div>
            <div>
                <img alt="err img"className={style.img} src={image}  />
            </div>
        </div>
    )
}