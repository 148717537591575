import React from 'react';
import Card from '../Card';
import styles from './Home.module.css'
import CheckoutForm from '../CheckoutForm';
import Empty from '../CheckoutForm/Empty';
import ProdottoCheckout from "../ImgProdotto/ProdottoCheckout.js"
export default function Home({ messaggi, addCart, addPlan, cartProd, empty, idp }) {

  return (
    <div className={styles.wrapper}>
      <div className={styles.container__card}>
        {messaggi.map((msg) => (
          <Card key={msg.id} msg={msg} addCart={addCart} addPlan={addPlan}
          />))}
    
      </div>

      <div className={styles.ContainerWrapperCheckout}>
        <h2 >CHECKOUT</h2>
        <div className={styles.wrapperCheckout}>
         
          {empty === true && <Empty />}
          {empty === false && <>
            <ProdottoCheckout className={styles.wrapperProd}/>
          <div className={styles.indicatorProd}></div>
            <CheckoutForm idp={idp} cartProd={cartProd} />
          </>}
        </div>
      </div>
    </div>

  );
}
