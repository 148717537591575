// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.ProdottoCheckout_wrapImgP__sp4wN {\n    max-width: 400px;\n    height: 150px;\n    margin-right: 15px;\n\n}\n\n.ProdottoCheckout_ImgP__tdGwM{\n    border-radius: 50% ;\n    width: 10rem\n}", "",{"version":3,"sources":["webpack://./src/components/ImgProdotto/ProdottoCheckout.module.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;;AAEtB;;AAEA;IACI,mBAAmB;IACnB;AACJ","sourcesContent":["\n.wrapImgP {\n    max-width: 400px;\n    height: 150px;\n    margin-right: 15px;\n\n}\n\n.ImgP{\n    border-radius: 50% ;\n    width: 10rem\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapImgP": "ProdottoCheckout_wrapImgP__sp4wN",
	"ImgP": "ProdottoCheckout_ImgP__tdGwM"
};
export default ___CSS_LOADER_EXPORT___;
