import styles from "./Card.module.css";
import * as React from 'react';
import image from "../../data/img/messaggi.png"

export default function Card({ msg, addCart, addPlan }) {

    return (
        <div className={styles.card}  onClick={() => { addCart(msg); addPlan() }} >
            <img className={styles.card__img} alt="ok img" src={image}/>
            <div className={styles.indicatorProd}></div>
            <div className={styles.description}>
            <p className={styles.cardQty}>{msg.qty} SMS</p>
            <p className={styles.card__price}>{msg.price}€ *iva esclusa</p>
            </div>
        </div>
)
}



