// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avviso_wrapperMain__wQomH{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 80vh;\n    background-color: #d8d8d8;\n}\n.Avviso_titolo__G1NEw{\n    padding:20px;\n    margin-top: 8rem;\n}\n\n.Avviso_img__Nu5Tp{\n    margin-top: 1rem;\n    width:350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Avviso/Avviso.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".wrapperMain{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 80vh;\n    background-color: #d8d8d8;\n}\n.titolo{\n    padding:20px;\n    margin-top: 8rem;\n}\n\n.img{\n    margin-top: 1rem;\n    width:350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperMain": "Avviso_wrapperMain__wQomH",
	"titolo": "Avviso_titolo__G1NEw",
	"img": "Avviso_img__Nu5Tp"
};
export default ___CSS_LOADER_EXPORT___;
