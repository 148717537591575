import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";


const logo = "https://www.i-salon.eu/public/immagini_login/default/logo_wegest_trasparente3.png"
export default function NavBar() {
  return (
    <Box>
      <AppBar color="default">
        <Toolbar variant="dense" disableGutters={true}>
        <a href="https://www.i-salon.eu/login/default.asp?login="> <img style={{ padding: "8px", height:"5em"}} src={logo}  alt="brand-logo" /></a>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

