import style from "./CheckoutForm.module.css";

import image from "../../data/img/empty-cart.gif"
export default function EmptyMod() {

    return (
        <div className={style.wrapImg} >
            <img  className={style.Img} alt="empty cart" src={image} />
        </div>
    )
}

