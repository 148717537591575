import style from  "./ErrorMod.module.css";

import image from "../../data/img/error.png"
export default function ErrorMod({ idp }) {
    const homepage = `${window.location.origin}/acquisto_sms/?IDP=${idp}`
    
    return (
        <div className={style.wrapperMain}>
            <div>
                <h1 className="titolo">Errore Pagamento</h1>
                <p>Siamo spiancenti, il pagamento non è andato a buon fine.</p>
                <a href={homepage}> <button className={style.Btn}>Torna agli acquisti</button></a>
            </div>
            <div>
                <img alt="err img"className={style.img} src={image}  />
            </div>
        </div>
    )
}