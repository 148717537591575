import Home from './components/Home'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import ErrorMod from './components/ErrorMod'
import SuccessMod from './components/SuccessMod'
import Avviso from './components/Avviso'
import sms from './data/sms.json'
import './index.css'
import { useState } from 'react'
// import "@stripe/stripe-js";

function App() {

  const messaggi = sms
  const [cartProd, setCartProd] = useState({});
  const addCart = (msg) => { setCartProd(msg) }
  const [empty, setEmpty] = useState(true)
  const addPlan = () => { setEmpty(false) }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const page = urlParams.get("p")
  const puntoId = urlParams.get("IDP")

  if (navigator.userAgent.search("Firefox") >= 0){
    return (
      <div>
      <NavBar />
    <Avviso/>
    <Footer />
    </div>
    )

  }else{
  
  return (
    <div>
      <NavBar />
      {!page && <>
        <Home messaggi={messaggi} addCart={addCart} cartProd={cartProd} idp={puntoId} addPlan={addPlan} empty={empty} />
      </>}
      {page === "s" && <SuccessMod cartProd={cartProd} idp={puntoId} />}
      {page === "c" && <ErrorMod />}
      <Footer />
    </div>
  );
}}

export default App;



