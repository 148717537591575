// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorMod_wrapperMain__P\\+Oeb{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 80vh;\n    background-color: #d8d8d8;\n}\n.ErrorMod_titolo__pxea3{\n    padding:20px;\n    margin-top: 8rem;\n}\n\n.ErrorMod_Btn__gsy56{\n    cursor: pointer;\n    height: 30px;\n    width: 130px;\n    color: #fff;\n    border: 1px #f08b38 solid;\n    outline: none;\n    border-radius: 4px;\n    background-color: #f08b38;\n    margin: 20px;\n}\n.ErrorMod_img__40Zei{\n    width:350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ErrorMod/ErrorMod.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,WAAW;AACf","sourcesContent":[".wrapperMain{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 80vh;\n    background-color: #d8d8d8;\n}\n.titolo{\n    padding:20px;\n    margin-top: 8rem;\n}\n\n.Btn{\n    cursor: pointer;\n    height: 30px;\n    width: 130px;\n    color: #fff;\n    border: 1px #f08b38 solid;\n    outline: none;\n    border-radius: 4px;\n    background-color: #f08b38;\n    margin: 20px;\n}\n.img{\n    width:350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperMain": "ErrorMod_wrapperMain__P+Oeb",
	"titolo": "ErrorMod_titolo__pxea3",
	"Btn": "ErrorMod_Btn__gsy56",
	"img": "ErrorMod_img__40Zei"
};
export default ___CSS_LOADER_EXPORT___;
