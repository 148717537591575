import style from  "./SuccessMod.module.css";
import { useEffect, useState } from 'react'
// import image from "../../data/img/success.png"
import aggiorna from "../../data/img/verificasms.png"

export default function SuccessMod({ idp }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id")
  const QTY = urlParams.get("QS")
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('Debug: Inizio della chiamata API');
      // Recupera visitedSessionIds dal LocalStorage
      const storedVisitedSessionIds = localStorage.getItem('visitedSessionIds');
      const parsedVisitedSessionIds = storedVisitedSessionIds ? new Set(JSON.parse(storedVisitedSessionIds)) : new Set();
  
    // Verifica se isLoading è true; in tal caso, esci senza eseguire una nuova richiesta
    if (isLoading) {
      console.log('Debug: La richiesta è già in corso, uscita.');
      return;
    }
  
    if (parsedVisitedSessionIds.has(sessionId)) {
      console.log('Debug: Questo sessionId è già stato visitato, uscita.');
      return;
    }
    // Altrimenti, procedi con la chiamata API
    const url = "https://www.wegest.it/stripe_sms/get_transation2.php"
    const paramsPost = "I=" + idp + "&Q=" + QTY + "&S=" + sessionId;

    setIsLoading(true); // Imposta il flag isLoading a true per indicare che la richiesta è in corso
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: paramsPost,
    })
      .then(response => response.json())
      .then(result => {
        console.log('Success:', result);
        // ... altro codice di elaborazione della risposta
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false); // Imposta il flag isLoading a false quando la richiesta è completata (indipendentemente dall'esito)

        // Aggiunge il sessionId alla lista dei sessionId visitati
        parsedVisitedSessionIds.add(sessionId);

        // Salva l'insieme aggiornato nel localStorage
        localStorage.setItem('visitedSessionIds', JSON.stringify(Array.from(parsedVisitedSessionIds)));

        console.log('Debug: Fine della chiamata API');
      });
  }, [idp, QTY, sessionId, isLoading]);


  const homepage = ` ${window.location.origin}/acquisto_sms/?IDP=${idp}`
 
  return (
    <div className={style.wrapperMain} >
      <div>
        <h1 >Pagamento eseguito </h1>
        <p>L'acquisto è stato caricato sul tuo account WeGest!</p>
        <p>Clicca sul tasto <em>verifica credito</em> per aggiornare i tuoi SMS.</p>
        <a href={homepage}><button className={style.Btn}>Torna agli acquisti</button></a>
      </div>
      <div>
        {/* <img className={style.img} alt="ok img" src={image}  /> */}
        <img className={style.img} alt="aggiorna" src={aggiorna}  />
      </div>

      </div>
    
  )
}