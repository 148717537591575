import styles from "./Footer.module.css";


const Footer = () => {
    return (
  
            <footer className={styles.Footer}>
                <ul>
                    <li>
                        <h4>Working Evolution S.r.l.</h4>
                    </li>
                    <li>Working Evolution S.r.l.
                        P.IVA 06577980821</li>
                    <li>REA: PA400790</li>
                    <li>Informativa protezione dati</li>

                </ul>

                <ul>
                    <li>
                        <h4>CONTATTI</h4>
                    </li>
                    <li>Assistenza: 0915640490</li>
                    <li>Commerciale: 3484708155</li>
                    <li>Amministrazione:</li>
                    <li>0917932311</li>

                </ul>

            </footer>

    );
};

export default Footer;
