// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckoutForm_SubBTN__AT-E2 {\n    cursor: pointer;\n    width: 150px;\n    height: 30px;\n    margin-top: 10px;\n    border: 1px solid;\n    background-color: #8DCDFF;\n    border-radius: 4px;\n    outline: none;\n    color:white;\n    font-size:12px;\n    padding: 7px;\n}\n\n.CheckoutForm_wrapperQP__Xf5vI{\n    padding:10px;\n}\n.CheckoutForm_wrapperTotale__-tMqN{\n    padding:10px;\n}\n.CheckoutForm_form__EO0-Z {\n    width: 400px;\n    height: 150px\n}\n\n.CheckoutForm_wrapImg__xFt8b {\n    max-width: 400px;\n    height: 150px\n}\n\n.CheckoutForm_Img__p96hG{\n    border-radius: 50% ;\n    width: 10rem\n}\n@media only screen and (max-width: 650px) {\n    .CheckoutForm_form__EO0-Z {\n        width: 80%;\n\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/CheckoutForm/CheckoutForm.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;IACZ;AACJ;;AAEA;IACI,gBAAgB;IAChB;AACJ;;AAEA;IACI,mBAAmB;IACnB;AACJ;AACA;IACI;QACI,UAAU;;IAEd;AACJ","sourcesContent":[".SubBTN {\n    cursor: pointer;\n    width: 150px;\n    height: 30px;\n    margin-top: 10px;\n    border: 1px solid;\n    background-color: #8DCDFF;\n    border-radius: 4px;\n    outline: none;\n    color:white;\n    font-size:12px;\n    padding: 7px;\n}\n\n.wrapperQP{\n    padding:10px;\n}\n.wrapperTotale{\n    padding:10px;\n}\n.form {\n    width: 400px;\n    height: 150px\n}\n\n.wrapImg {\n    max-width: 400px;\n    height: 150px\n}\n\n.Img{\n    border-radius: 50% ;\n    width: 10rem\n}\n@media only screen and (max-width: 650px) {\n    .form {\n        width: 80%;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubBTN": "CheckoutForm_SubBTN__AT-E2",
	"wrapperQP": "CheckoutForm_wrapperQP__Xf5vI",
	"wrapperTotale": "CheckoutForm_wrapperTotale__-tMqN",
	"form": "CheckoutForm_form__EO0-Z",
	"wrapImg": "CheckoutForm_wrapImg__xFt8b",
	"Img": "CheckoutForm_Img__p96hG"
};
export default ___CSS_LOADER_EXPORT___;
