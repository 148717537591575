
import style from './ProdottoCheckout.module.css'
const image = "https://www.wegest.it/shop/wp-content/uploads/2019/05/sms.jpg"
const ProdottoCheckout = () => {
     return (
         <div className={style.wrapImgP} >
<img className={style.ImgP} alt="card prod" src={image} />
         </div>
     )
 }

 export default ProdottoCheckout