// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuccessMod_wrapperMain__O1Z\\+e{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 80vh;\n    background-color: #d8d8d8;\n    margin-bottom:0 ;\n}\nh1{\n    padding:20px;\n    margin-top: 8rem;\n\n    font-weight: bolder;\n}\n\n.SuccessMod_Btn__dzzbX{\n    cursor: pointer;\n    height: 30px;\n    width: 130px;\n    color: #fff;\n    border: 1px #04bf6b solid;\n    outline: none;\n    border-radius: 4px;\n    background-color: #04bf6b;\n    margin: 20px;\n}\n.SuccessMod_img__q5o6l{\n    margin-top:20px;\n    width:350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SuccessMod/SuccessMod.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,gBAAgB;;IAEhB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".wrapperMain{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 80vh;\n    background-color: #d8d8d8;\n    margin-bottom:0 ;\n}\nh1{\n    padding:20px;\n    margin-top: 8rem;\n\n    font-weight: bolder;\n}\n\n.Btn{\n    cursor: pointer;\n    height: 30px;\n    width: 130px;\n    color: #fff;\n    border: 1px #04bf6b solid;\n    outline: none;\n    border-radius: 4px;\n    background-color: #04bf6b;\n    margin: 20px;\n}\n.img{\n    margin-top:20px;\n    width:350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperMain": "SuccessMod_wrapperMain__O1Z+e",
	"Btn": "SuccessMod_Btn__dzzbX",
	"img": "SuccessMod_img__q5o6l"
};
export default ___CSS_LOADER_EXPORT___;
