import { useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import style from "./CheckoutForm.module.css";
import {AddVat} from '../AddVat';

let stripePromise;
const getStripe = () => {

  if (!stripePromise) {
    stripePromise = loadStripe('pk_live_SKtLZwdy1Jccx3194QAfyv2I');
    // stripePromise = loadStripe('pk_test_ITYwoJ2YZQLIk1IuLxQ9WSgz');
  }
  return stripePromise;
};
export default function CheckoutForm({ cartProd, idp }) {
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const item = {
    price: cartProd.price_id,
    quantity: 1
  };

  const prodId = cartProd.id
  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    successUrl: `${window.location.origin}/acquisto_sms/?p=s&session_id={CHECKOUT_SESSION_ID}&IDP=${idp}&PID=${prodId}&QS=${cartProd.qty}`,
    cancelUrl: `${window.location.origin}/acquisto_sms/?p=c&IDP=${idp}`
  };

  const redirectToCheckout = async (ev) => {
    ev.preventDefault()
    setLoading(true);
    console.log("redirectToCheckout");
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);
    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);


  return (

    <form className={style.form}>
     
      <div className={style.wrapperTotale}>
        <section className={style.wrapperQP}>
        <p>1 x {cartProd.qty} SMS </p> 
        <p>{cartProd.price} € + IVA 22% </p> 
        </section>
        <p>
          <strong> Totale: <AddVat value={cartProd.price} vat={22}/> €</strong></p>
      </div>
      <button className={style.SubBTN} type="submit" disabled={isLoading} onClick={redirectToCheckout}>Procedi all'acquisto</button>
    </form>

  )
};